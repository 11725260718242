import React from 'react';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const delay = 200;
const cardDelay = 50;
const anchor = "#redeem-section"; // data-aos-anchor
const placement = "top-bottom";

function RedeemSection() {
  return (
    <div id="redeem-section" className="bg-white flex flex-col py-20 px-6 lg:px-8">
      <div className="max-w-6xl mx-auto text-center">
        <h2
          className="text-3xl leading-8 tracking-tight sm:text-4xl"
          data-aos="fade-right"
          data-aos-anchor={anchor}
          data-aos-anchor-placement="top-bottom"
        >
          Redeem your YARDS
        </h2>
        <p
          data-aos="fade-up"
          data-aos-delay={`${delay * 1}`}
          data-aos-anchor={anchor}
          data-aos-anchor-placement={placement}
          className="text-xl mt-8 lg:mx-auto max-w-3xl">
          When you sign up for an Andrew YARDS Credit Card that offers concierge services, you’ll gain access to your own personal assistant “Andi” who can help you with travel planning, resort stays, experiences, events, shopping, reservations, and even your everyday needs. You can contact your concierge via a 1-800 number, dedicated Andrew Concierge email, or live support to get help with any issue you come across around the corner and across the globe, whether you need tickets to a sold-out event, want to book a tee time, or simply using your exclusive Andrew YARDS.

        </p>
        <div className="grid cols-1 md:grid-cols-3 gap-8 mt-12">
          {/* Updated usage of RedeemCard with new images and titles */}
          <RedeemCard image="/images/redeem/redeem-travel.png" title="Travel, trips, and bookings" icon="/images/redeem/plane.png" index={0} />
          <RedeemCard image="/images/redeem/redeem-exclusive.png" title="Exclusive access" icon="/images/redeem/key-01.png" index={1} />
          <RedeemCard image="/images/redeem/redeem-limited.png" title="Limited-release merch-content, and events" icon="/images/redeem/calendar.png" index={2} />
        </div>
      </div>
    </div>
  );
}

function RedeemCard({ image, title, icon, index }) {
  const iconRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    gsap.set(iconRef.current, { y: 0 });
  }, []);

  const handleMouseEnter = () => {
    gsap.to(overlayRef.current, { autoAlpha: 0, duration: 0.2 });
    gsap.to(iconRef.current, { y: 30, duration: 0.2 });
  };

  const handleMouseLeave = () => {
    gsap.to(overlayRef.current, { autoAlpha: 1, duration: 0.2 });
    gsap.to(iconRef.current, { y: 0, duration: 0.2 });
  };

  return (
    <div className="flex flex-col"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}

      data-aos="fade-up"
      data-aos-delay={`${(delay * 2) + (index * cardDelay)}`}
      data-aos-anchor={anchor}
      data-aos-anchor-placement={placement}
    >
      <div className="relative rounded-xl overflow-hidden"
      >
        <img src={image} alt="" className="w-full h-52 object-cover" />
        <div ref={overlayRef} className="absolute inset-0 bg-black bg-opacity-50" />
        <div ref={iconRef} className="absolute w-16 h-16 inset-0 rounded-full border-4 border-white p-12 m-auto flex justify-center items-center">
          <img src={icon} alt="" className="absolute" />
        </div>
      </div>
      <h3 className="text-2xl text-left font-bold my-4">{title}</h3>
    </div>
  );
}

export default RedeemSection;