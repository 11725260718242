import React from 'react';
import PasswordResetForm from '@components/PasswordResetForm';

function Home() {
  return (
    <>
      <div class="flex-grow mt-20 px-6 lg:px-0 flex flex-col items-center justify-center">
        <div
          data-aos="fade-in"
          className="w-full max-w-md bg-white shadow-lg border border-neutral-100 rounded-2xl p-10 flex flex-col items-center space-y-2">
          <img className="mx-auto h-12 w-auto" src="/images/forgot-icon.png" alt="Andrew Logo" />
          <h2 className="text-2xl  tracking-tight">
            Forgot password?
          </h2>
          <p className=" text-gray-600 pb-4">
            No worries, we'll send you reset instructions.
          </p>
          <PasswordResetForm />
        </div>
      </div>
    </>
  );
}

export default Home;