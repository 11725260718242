import React from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import { useLocation } from 'react-router-dom'; // Import useLocation

const Layout = ({ children, className }) => {

  const location = useLocation(); // Get the current location
  const layoutClass = location.pathname === '/' ? 'bg-white' : 'bg-neutral-100'; // Set class based on route

  return (
    <div className={`min-h-screen flex flex-col ${layoutClass} ${className}`}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;