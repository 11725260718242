import React from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function IphoneImage() {
  const floatingContainerRef = React.useRef(null);
  const shadowRef = React.useRef(null);
  const cardRef = React.useRef(null); // Add a ref for the card

  useGSAP(() => {
    const duration = 4;
    const distance = -24;

    gsap.to(floatingContainerRef.current, {
      duration: duration,
      y: distance,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut"
    });

    // Reverse the animation by changing the opacity values
    gsap.to(shadowRef.current, {
      duration: duration,
      opacity: .8, // Start fully visible
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      scrollTrigger: {
        trigger: "#herosection",
        start: "top bottom",
        toggleActions: "play pause play pause"
      }
    });

    // Animation for the credit card image to float slightly higher
    gsap.to(cardRef.current, {
      duration: duration,
      y: distance / 1.75, // Adjust the vertical position to float higher
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      delay: 1.5 // Delay the start of the animation
    });
  }, { scope: [floatingContainerRef, shadowRef] });



  React.useEffect(() => {
    const element = floatingContainerRef.current;
    gsap.set(element, { transformPerspective: 600 }); // Set a perspective to enhance the 3D effect
    const clamp = 2;

    const handleMouseMove = (event) => {
      const rect = element.getBoundingClientRect();
      const x = event.clientX - rect.left; // x position within the element
      const y = event.clientY - rect.top; // y position within the element
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const distanceX = x - centerX;
      const distanceY = y - centerY;
      const rotationY = gsap.utils.clamp(-1, 1, distanceX / 10); // Clamp the rotation between -10 and 10 degrees
      const rotationX = gsap.utils.clamp(clamp * -1, clamp, -distanceY / 10); // Clamp the rotation between -10 and 10 degrees, inverted for a natural effect

      gsap.to(element, {
        rotationY: rotationY,
        rotationX: rotationX,
        ease: "power1.inOut",
        duration: 0.2
      });
    };

    const handleMouseEnter = () => {
      element.addEventListener('mousemove', handleMouseMove);
    };

    const handleMouseLeave = () => {
      element.removeEventListener('mousemove', handleMouseMove);
      gsap.to(element, {
        rotationY: 0,
        rotationX: 0, // Reset rotationX when mouse leaves
        ease: "power1.inOut",
        duration: 0.5
      });
    };

    element.addEventListener('mouseenter', handleMouseEnter);
    element.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter);
      element.removeEventListener('mouseleave', handleMouseLeave);
      element.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  React.useEffect(() => {
    const sheenDuration = .8;
    const sheenEase = "power4.out";

    const sheenEnter = () => {
      gsap.to(cardRef.current, {
        '--sheen-left': '125%', // Custom property for animation
        duration: sheenDuration,
        ease: sheenEase
      });
    };

    const sheenLeave = () => {
      gsap.to(cardRef.current, {
        '--sheen-left': '-75%', // Reset to initial position
        duration: sheenDuration,
        ease: sheenEase
      });
    };

    const container = floatingContainerRef.current;
    container.addEventListener('mouseenter', sheenEnter);
    container.addEventListener('mouseleave', sheenLeave);

    return () => {
      container.removeEventListener('mouseenter', sheenEnter);
      container.removeEventListener('mouseleave', sheenLeave);
    };
  }, []);

  return (
    <div className="relative mb-14 lg:mb-0 mt-36 md:mt-48 lg:flex-shrink-0 lg:flex-grow max-w-full">
      <div ref={floatingContainerRef} className="relative w-[150%] max-w-[120%] md:left-0 lg:left-10 xl:left-48" >
        <div ref={cardRef} className="cardRef-sheen absolute -top-[20%] left-8 sm:left-16 z-20 w-9/12">
          <img src="/images/credit-card.png" alt="Card" className="" />
        </div>

        <img src="/images/andrew-iphone.png" alt="iPhone" className="max-w-[80%] md:max-w-[514px]" />
        {/* <img src="/images/shadow.png" alt="Shadow" ref={shadowRef} id="shadow" className="absolute bottom-0 w-full -z-10 -left-5 opacity-50" /> */}

      </div>
      <div ref={shadowRef} id="shadow" className="absolute bottom-14 w-[50%] -z-10 left-[25%] xl:left-[64%] opacity-40" style={{
        height: '80%', // Increased height to extend beyond the container
        backgroundColor: 'rgb(0,0,0,.8)',
        boxShadow: '0px 30px 60px rgba(0,0,0,0.5)', // Deeper and more pronounced shadow
        filter: 'blur(16px)', // Added blur effect
        transform: 'translateY(20%) skewX(-20deg)', // Shift upwards to align with the top of the iPhone
        borderTop: 'solid 1px transparent', // Ensure top border is flat
        borderBottom: 'solid 1px transparent', // Ensure bottom border is flat

      }}></div>
    </div >
  );
}

export default IphoneImage;