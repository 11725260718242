import React from 'react';
import LoginForm from './LoginForm'; // Import LoginForm component
import IphoneImage from './IphoneImage'; // Import the new IphoneImage component
import { CloudHSMV2 } from 'aws-sdk';

const delay = 200;

function HeroSection() {
  return (
    <div className="relative isolate pt-14 min-h-[860px] overflow-x-hidden overflow-y-hidden max-w-full">
      <img
        data-aos="fade-in"
        data-aos-delay={`${delay}`}
        data-aos-duration="600"
        src="/images/hero-bg.svg" alt="Background" className="absolute top-0 right-0 max-w-7xl object-cover -z-20" />

      <div className="mx-auto max-w-7xl px-6 pt-24 md:py-8 lg:py-0 lg:flex lg:items-center lg:gap-x-10 lg:px-8">

        <div
          data-aos="fade-in"
          data-aos-delay={`${delay}`}
          className="mx-auto max-w-md lg:mx-0 lg:flex-auto bg-neutral-100 border shadow rounded-2xl p-10 flex flex-col items-center space-y-2">
          <img className="mx-auto h-12 w-auto" src="/images/logo-icon.png" alt="Andrew Logo" />
          <h2 className="text-3xl tracking-tight">
            Welcome back
          </h2>
          <p className="text-lg leading-8 text-gray-600">
            Life Well Played™
          </p>
          <LoginForm />
        </div>
        <div className="mx-auto max-w-lg lg:mx-0 lg:flex-auto"
          data-aos="fade-down"
          data-aos-delay={`${delay * 2}`}
          data-aos-duration="1000"
          data-aos-ease="in-out"
        >
          <IphoneImage />
        </div>
      </div>
    </div>
  );
}
export default HeroSection;