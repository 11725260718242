import React from 'react';
import { Link } from 'react-router-dom'

function Header() {
  return (
    <header className="bg-white md:bg-transparent flex justify-center absolute inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between w-full max-w-full p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1"
          data-aos="fade-in"
        // data-aos-duration="1000"
        >
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Andrew YARDS</span>
            <img className="w-auto" src="/images/logo.png" alt="Andrew Logo" />
          </Link>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Home</a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">About</a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Services</a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Contact</a> */}
        </div>
      </nav>
    </header>
  );
}

export default Header;