import React from 'react';

function Footer() {
  return (
    <footer className="bg-[#031c34]">
      <div className="flex flex-col md:flex-row items-center justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-4 md:mb-0">
          <img src="/images/logo-reverse.png" alt="Logo" className="h-8 w-auto" />
        </div>
        <div className="flex-grow text-center space-x-6 mb-4 md:mb-0 px-4 flex-wrap leading-9">
          <a href="https://www.andrew.golf/terms-of-service" className="text-white hover:text-opacity-75 inline-block" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
          <a href="https://www.andrew.golf/privacy-policy" className="text-white hover:text-opacity-75 inline-block" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          {/* <a href="#" className="text-white hover:text-opacity-75 inline-block" target="_blank" rel="noopener noreferrer">Contact Us</a> */}
          <a href="https://www.andrew.golf/" className="text-white hover:text-opacity-75 inline-block" target="_blank" rel="noopener noreferrer">App</a>
        </div>
        <div className="text-white text-right text-opacity-50 text-sm">
          <p className="text-right">© 2024 Andrew Golf<br />
            Andrew YARDS™</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;