import React from 'react';
import HeroSection from '@components/HeroSection';
import OverviewSection from '@components/OverviewSection';
import HowItWorksSection from '@components/HowItWorksSection';
import EarnSection from '@components/EarnSection';
import RedeemSection from '@components/RedeemSection';

function Home() {
  return (
    <>
      <HeroSection />
      <OverviewSection />
      <HowItWorksSection />
      <EarnSection />
      <RedeemSection />
    </>
  );
}

export default Home;