import React from 'react';

function OverviewSection() {
  const delay = 100;
  const anchor = ".features-section-container"; // data-aos-anchor
  const placement = "top-bottom";

  return (
    <div
      className="features-section-container px-6 lg:px-8 md:min-h-[700px] bg-teal-800 py-20 text-white" style={{ backgroundImage: 'url(/images/features-bg.png)', backgroundSize: 'cover' }}>
      <div className="max-w-6xl mx-auto space-y-8 lg:text-center">

        <h2
          // data-aos="fade-up"
          // data-aos-anchor={anchor}
          // data-aos-anchor-location={anchorLocation}

          data-aos="fade-right"
          data-aos-anchor={anchor}
          data-aos-anchor-placement={placement}


          className="text-3xl leading-8 tracking-tight sm:text-4xl text-white">
          Andrew YARDS
        </h2>
        <p
          data-aos="fade-up"
          data-aos-delay={`${delay * 1}`}
          data-aos-anchor={anchor}
          data-aos-anchor-placement={placement}
          className="text-xl lg:mx-auto max-w-3xl pb-4">
          <span className="opacity-70">
            Andrew YARDS offers a wide range of rewards on most Andrew activities and everyday purchases so you can experience Life Well Played™. Earn YARDS with your Andrew Card then use YARDS for travel, resort stays, immersive experiences, shopping, merchandise and exclusive events, or even to cover recent Andrew Card charges.
          </span>
        </p>
        <img
          data-aos="fade-up"
          data-aos-delay={`${delay * 2}`}
          data-aos-anchor={anchor}
          data-aos-anchor-placement={placement}
          src="/images/overview-image.png" className="rounded-2xl w-full max-h-[394px] object-cover" />
      </div>
    </div >)
}

export default OverviewSection;