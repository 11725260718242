import React, { useState } from 'react';
import Loader from 'react-spinners/PulseLoader';
import { Link } from 'react-router-dom';

function LoginForm() {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    setError(''); // Clear any existing errors on new submit
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setError('Invalid username or password'); // Set error after fake delay
    }, 900);
  }

  return (
    <form className="mt-8 space-y-6 w-full" onSubmit={submitForm}>
      <input type="hidden" name="remember" value="true" />
      <div className="rounded-md shadow-sm space-y-4">
        <div>
          <label htmlFor="email-address" className="sr-only">Email address</label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
            autoFocus
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            placeholder="Password"
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm">
          <Link
            to="/reset-password"
            className="font-medium text-green-700 hover:text-green-500"
          >Forgot password?</Link>
        </div>
      </div>
      <div>
        <button
          type="submit"
          disabled={submitting} // Disable button while submitting
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        ><span >
            {submitting ? (<div
              style={{ paddingTop: '1px' }}><Loader
                color={'#ffffff'}
                speedMultiplier={1}
                size={10}
              /></div>) : 'Login'}</span>
        </button>
      </div>
      {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
    </form >
  );
}

export default LoginForm;