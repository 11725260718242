import React from 'react';
import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const delay = 200;
const cardDelay = 50;
const anchor = "#howitworks-section"; // data-aos-anchor
const placement = "top-bottom";

function HowItWorksSection() {

  return (
    <div id="howitworks-section" className="bg-white text-black py-20 px-6 lg:px-8 ">
      <div className="max-w-6xl mx-auto space-y-8 lg:text-center">

        <h2
          data-aos="fade-right"
          data-aos-anchor={anchor}
          data-aos-anchor-placement={placement}
          className="text-3xl leading-8 tracking-tight sm:text-4xl">
          How it works?
        </h2>
        <div
          className="grid grid-cols-1 gap-y-8 pt-4 md:grid-cols-3 md:gap-y-0 md:gap-x-8 relative w-full"
        >
          {/* Example usage of FeatureCard */}
          <HowItWorksCard
            icon="/images/how-earn-icon.png"
            title="Earn"
            description="We like things simple and straight down the fairway too. Just use your Andrew Card for activities and purchases and watch the YARDS add up. There's no limit to how many YARDS you can earn, and don't worry about going OB on expiration dates because there aren't any. "
            index={0}
          />
          <HowItWorksCard
            index={1}
            icon="/images/earn/explore-icon.png"
            title="Explore"
            description="The Andrew YARDS program offers a breadth of exceptional rewards and the greatest transfer partners of any credit card loyalty program. From travel to top brands, and just about everything in between, use Andrew YARDS on the things you actually care about." />
          <HowItWorksCard
            index={2}
            icon="/images/how-redeem-icon.png"
            title="Redeem"
            description="Simply log in to your Andrew Card account to redeem Andrew YARDS or select YARDS when you transact in app, online, or in-store with participating merchants. With thousands of ways to enjoy YARDS now that's Life Well Played™." />
        </div>
      </div>
    </div>)
}

function HowItWorksCard({ icon, title, description, index }) {
  const cardRef = useRef(null);

  return (
    <div
      data-aos="fade-up"
      data-aos-delay={`${(delay * 2) + (index * cardDelay)}`}
      data-aos-anchor={anchor}
      data-aos-anchor-placement={placement}

    >
      <div
        ref={cardRef}
        className="card-hover-effect h-full space-y-4 flex flex-col items-center rounded-2xl p-6 bg-teal-800 text-white bg-cover"
        style={{
          backgroundImage: 'url(/images/features-bg.png)',
          boxShadow: 'none',
          borderBottom: '2px solid transparent', // Default state
          boxSizing: 'border-box',
          paddingBottom: 'calc(1rem - 2px)' // Adjust padding to compensate for border thickness
        }}
        onMouseEnter={() => {
          gsap.to(cardRef.current, {
            y: -16,
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
            borderBottom: '6px solid #10b981',
            paddingBottom: 'calc(1rem - 6px)', // Adjust padding to keep the same overall height
            delay: 0,
            duration: 0.25,
          });
        }}
        onMouseLeave={() => {
          gsap.to(cardRef.current, {
            y: 0,
            boxShadow: 'none',
            borderBottom: '2px solid transparent',
            paddingBottom: 'calc(1rem - 2px)', // Return padding to default
            delay: 0,
            duration: 0.25,
          });
        }}
      >
        <img src={icon} className="h-16 ml-4" />
        <h3 className="text-xl text-neutral-100">{title}</h3>
        <p className="pb-4 text-neutral-100 font-normal">{description}</p>
      </div>
    </div>
  );
}
export default HowItWorksSection;