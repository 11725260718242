import React, { useState } from 'react';
import Loader from 'react-spinners/PulseLoader';
import { Link } from 'react-router-dom';

function PasswordResetForm() {
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    setMessage('');
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setMessage(<>
        <h4 className="pb-4 text-green-500 font-bold">Password Reset Requested</h4 >
        <p>If your email matches an account, a reset link has been sent. Check your inbox and follow the instructions. If you don't see it, check your spam folder.</p>
      </>);
    }, 900);
  }

  return (
    <form className="mt-8 space-y-6 w-full" onSubmit={submitForm}>
      <div className="rounded-md shadow-sm space-y-4">
        <div>
          <label htmlFor="email-address" className="sr-only">Email address</label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
            autoFocus
          />
        </div>
      </div>
      <div>
        <button
          type="submit"
          disabled={submitting}
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <span>
            {submitting ? (
              <div style={{ paddingTop: '1px' }}>
                <Loader color={'#ffffff'} speedMultiplier={1} size={10} />
              </div>
            ) : 'Reset Password'}
          </span>
        </button>
      </div>
      {message && <div className="text-sm mt-2">{message}</div>}
      <div className="text-sm mt-4 text-center">
        <Link to="/" className="font-medium text-green-700 hover:text-green-500">
          ← Back to login
        </Link>
      </div>
    </form>
  );
}

export default PasswordResetForm;
