import React from 'react';

const delay = 200;
const anchor = "#earn-section"; // data-aos-anchor
const placement = "top-bottom";

function EarnSection() {
  return (
    <div id="earn-section" className="bg-gray-100 flex flex-col py-20 px-6 lg:px-0">
      <div
        className="w-full p-8 max-w-6xl mx-auto lg:text-center bg-[#031C34] rounded-xl"
        data-aos="fade-up"
        data-aos-anchor={anchor}
        data-aos-anchor-placement={placement}
      >
        <div className="flex flex-col-reverse items-center md:items-left px-18 py-4 md:flex-row md:items-center justify-around">
          <div className="space-y-4 text-left">
            <h2 className="text-4xl text-white mb-8"
              data-aos="fade-right"
              data-aos-anchor={anchor}
              data-aos-anchor-placement={placement}
            >
              Earn YARDS
            </h2>
            <p className="text-white text-xl pb-4"
              data-aos="fade-up"
              data-aos-delay={`${delay * 1}`}
              data-aos-anchor={anchor}
              data-aos-anchor-placement={placement}
            >
              Here's how you can earn YARDS quickly and easily.
            </p>
            <ul className="list-none list-inside text-white text-lg leading-9"
              data-aos="fade-up"
              data-aos-delay={`${delay * 2}`}
              data-aos-anchor={anchor}
              data-aos-anchor-placement={placement}
            >
              <li><img src="/images/check-circle-broken.svg" alt="Check" className="inline-block mr-4" /><span className="align-middle">Wager in the Andrew app</span></li>
              <li><img src="/images/check-circle-broken.svg" alt="Check" className="inline-block mr-4" /><span className="align-middle">Pay for your tee time</span></li>
              <li><img src="/images/check-circle-broken.svg" alt="Check" className="inline-block mr-4" /><span className="align-middle">Your favorite golf purchases</span></li>
              <li><img src="/images/check-circle-broken.svg" alt="Check" className="inline-block mr-4" /><span className="align-middle">Attending tournaments and events</span></li>
              <li><img src="/images/check-circle-broken.svg" alt="Check" className="inline-block mr-4" /><span className="align-middle">Golf club dues</span></li>
            </ul>
          </div>
          <div className="text-center mb-12 md:mb-0">
            <img
              src="/images/earn-image.png"
              alt="Golfer celebrating a putt"
              className="w-64 h-64 md:w-80 md:h-80 object-cover rounded-full"
              data-aos="fade-in"
              data-aos-delay={`${delay * 3}`}
              data-aos-anchor={anchor}
              data-aos-anchor-placement={placement} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarnSection;